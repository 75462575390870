/* eslint-disable react/no-danger */
import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Img from 'gatsby-image';
import moment from 'moment';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Layout from '../components/Layout';
import SEO from '../components/seo';
// import user from '../../static/img/Employee_testimonials/employeeImg1.png';
import '../sass/_employee-testimonial-detail.scss';

export default class BlogTemplate extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      data: {
        markdownRemark: {
          html,
          // eslint-disable-next-line camelcase
          frontmatter: { title, post, place, date, background_img, profile_pic },
        },
      },
    } = this.props;

    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="Employee Testimonial Detail" />

        <BackgroundImage
          Tag="section"
          className="bg_wrapper cover_image"
          fluid={background_img.childImageSharp.fluid}
        />

        <Container className="positive-relative">
          <Row>
            <Col xs={12} lg={12}>
              <div className="d-flex profile_box flex-column flex-lg-row align-items-center mt-lg-4">
                <div className="justify-content-center user_profile">
                  <div className="user-image-wrapper">
                    <Img
                      className="profile_image shadow img-fluid position-relative"
                      fluid={profile_pic.childImageSharp.fluid}
                    />
                  </div>
                </div>
                <div className="d-none d-lg-block align-self-lg-end p-3 py-lg-0 px-lg-2 align-self-center text-center text-lg-left">
                  <h4 className="text-bold text-appcolor pt-3">{title}</h4>
                  <h6 className="m-0">
                    {post}, {place}
                  </h6>
                  <small className="text-muted">
                    <time
                      dateTime={moment(new Date(date).toISOString()).format('ddd, DD MMM YYYY')}
                    >
                      {moment(new Date(date).toISOString()).format('ddd, DD MMM YYYY')}
                    </time>
                  </small>
                </div>
              </div>
              <div className="user_details d-block d-lg-none align-self-lg-end py-3 py-lg-0 align-self-center text-center text-lg-left">
                <h3 className="text-bold text-appcolor pt-3">{title}</h3>
                <h6 className="m-0">
                  {post}, {place}
                </h6>
                <small className="text-muted">
                  <time dateTime={moment(new Date(date).toISOString()).format('ddd, DD MMM YYYY')}>
                    {moment(new Date(date).toISOString()).format('ddd, DD MMM YYYY')}
                  </time>
                </small>
              </div>
            </Col>
            <Col className="description mt-192">
              <div className="mx-3 mx-lg-0">
                <div className="blog" dangerouslySetInnerHTML={{ __html: html }} />
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query BlogBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        post
        place
        date
        background_img {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        profile_pic {
          childImageSharp {
            fluid(maxWidth: 512, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
